<script>
export default {
    name: 'AboutHendrik',
    data: () => {
        return {
            bios: [
                {
                    id: 1,
                    title: 'Online Dating mit System aufbauen',
                    bio: 'Wie du eingangs erfahren hast, haben Frauen auf Online-Dating-Plattformen sehr viel Auswahl. Die gute Nachricht ist, dass 95% aller Männer dort nicht wissen, was sie tun. Wenn du also mit System ein Profil für Online-Dating-Apps aufbaust, wirst du dich von deiner zahlreichen Konkurrenz unterscheiden und viele Matches bekommen. Dir muss aber auch klar sein, dass es nicht ausreicht, irgendwelche alten Bilder, Selfies oder Fotos von einem Fotoshooting zu verwenden. Du musst auch nicht besonders gut aussehen oder groß sein, um online erfolgreich Frauen kennenzulernen. Stattdessen musst du lernen, wie du dich mit all deinen positiven Eigenschaften und deinem interessanten Lifestyle richtig über Bilder präsentierst. Zusätzlich brauchst du ein einfaches Skript, um mit Copy&Paste Nachrichten ohne viel Nachzudenken Dates zu bekommen.',
                },
                {
                    id: 2,
                    title: 'Ansprechen im echten Leben lernen',
                    bio: 'Wenn du Frauen im Alltag ansprechen und kennenlernen kannst, wirst du dadurch Frauen treffen, die nicht ständig von der riesigen Auswahl an Männern auf Online-Dating-Plattformen oder Instagram überflutet werden. Allerdings werden Frauen heutzutage auch oft angesprochen, da viele Männer diesen Vorteil bereits erkannt haben. Deshalb ist es entscheidend, dass du dich von den typischen Pick-Up-Artists unterscheidest. Das gelingt dir, indem du Frauen auf eine natürliche und authentische Weise ansprichst. Außerdem musst du lernen, wie du ein authentisches Flirt-Gespräch aufbaust und dich richtig vermarktest. Achtung! Frauen aktiv anzusprechen und dadurch Dates zu bekommen, ist eine Königsdisziplin! Du musst einer Frau innerhalb weniger Sekunden klar machen, dass es sich lohnt, mit einem fremden Mann zu reden und ihn später auf einem Date wiedersehen zu wollen. Doch keine Angst! Ich habe ein klares Schritt für Schritt System, mit dem du das innerhalb von wenigen Wochen lernen kannst.',
                },
                {
                    id: 3,
                    title: 'Instagram als Digitale Visitenkarte nutzen',
                    bio: 'Eine digitale Visitenkarte in Form eines Instagram Profils ist in der modernen Dating-Welt Pflicht. Eine Frau, die dich auf einer Online-Dating-Plattform matcht oder die du aktiv angesprochen hast, bist du immer noch ein fremder Mann. Nur weil du ein Match mit einer Frau hast oder sie ansprichst und 10 Minuten mit ihr redest, heißt das nicht, dass sie dich richtig einschätzen kann. Selbst nach einem langen Gespräch oder einem ausführlichen Chat wird sie nur einen kleinen Bruchteil deiner Persönlichkeit kennengelernt haben. Wenn du aber ein Social-Media-Profil richtig aufbaust, wird sie dadurch sehr schnell alle nötigen Informationen zu dir bekommen, und dadurch wird sich die Date- Wahrscheinlichkeit um 80 % steigern! Außerdem ist Instagram die beste Dating App der Welt. Du kannst dort wunderbar passende Frauen finden, anschreiben und kennenlernen.',
                },
            ],
        };
    },
}
</script>

<template>
    <div class="mt-10 sm:mt-20">
        <div class="font-general-regular container mx-auto block sm:flex sm:justify-between sm:items-center">
            <div class="w-full">
                <p id="introText"
                    class="font-general-medium mt-2 text-lg text-center leading-none text-ternary-dark dark:text-ternary-light text-4xl sm:text-4xl">
                    Das erreichst du in einem Coaching bei <br /> Hendrik Mati:
                </p>
                <br />
                <div v-for="bio in bios" :key="bio.id"
                    class="flex ml-4 font-general-regular mt-4 mb-4 text-ternary-dark dark:text-ternary-light text-lg">
                    <div class="flex flex-col pl-4 text-left">
                        <p
                            class="text-md text-ternary-dark mb-1 text-2xl sm:text-2xl mt-2 font-bold dark:text-ternary-light">
                            {{ bio.title }}
                        </p>
                        <p class="text-md text-ternary-dark mt-2 mb-2 mr-3 dark:text-ternary-light">{{ bio.bio }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
#introText {
    line-height: 2.8rem;
}
</style>