<script>
import Button from '../../components/reusable/Button.vue';

export default {
	components: {
		Button,
	},
	setup() {
		return {
			vorgehen: [
				{
					id: 1,
					title: 'Schritt',
					content: 'Im ersten Schritt bringe ich dir die Flirt-Psychologie bei, damit du lernst, wie du dich richtig bei attraktiven Frauen vermarktest und wie du dich dadurch von deiner männlichen Konkurrenz unterscheidest. Zusätzlich bekommst du eine Stilberatung für Online-Bilder und die Praxis, um deine wahrgenommene Attraktivität sowohl online als auch beim Frauen-Ansprechen zu steigern. Außerdem stärken wir dein Mindset mit Techniken aus dem Mentaltraining, damit du beim Frauen-Ansprechen und auf Dates selbstsicher bist.',
					icon: "individuellFahrplan"
				},
				{
					id: 2,
					title: 'Schritt',
					content: 'Wir erstellen gemeinsam Bilder für deine Online-Dating-Profile und Instagram, die nachweislich gut bei Frauen ankommen. Im Anschluss setzen wir mit einer klaren Strategie neue Online-Dating-Profile auf und steuern den Algorithmus so, dass du gleich zu Beginn viele Matches bekommst. Zusätzlich bauen wir dir ein Instagram-Profil auf oder überarbeiten dein Aktuelles, um die Date-Quote von Frauen, die du offline oder online kennenlernst, um 80% zu steigern.',
					icon: 'aktionPraxis'
				},
				{
					id: 3,
					title: 'Schritt',
					content: 'Du sammelst hier erste praktische Erfahrungen im Frauen-Ansprechen. Wenn du möchtest, wirst du an die Hand genommen, damit es dir leichter fällt, deine Widerstände beim Frauen-Ansprechen zu überwinden. Ich bringe dir bei, wie du Frauen in verschiedenen Situationen richtig ansprichst und wie du ein gutes Flirtgespräch aufbaust. Dafür bekommst du individuelle Leitfäden und einfache Praxisübungen, damit du immer weißt was du machen musst. Deine Flirt-Gespräche werden wir auch regelmäßig analysieren und verbessern!',
					icon: 'vorbereitungPraxis'
				},
				{
					id: 4,
					title: 'Schritt',
					content: 'Hier bekommst du mein “Chat2Date Skript“. Das ist eine jahrelang erprobte Strategie, um ohne viel Nachzudenken mit Copy&Paste Nachrichten richtig mit Frauen zu chatten und schnell zu einem Date zu kommen. Dadurch wirst du immer wissen, wie du Frauen richtig anschreibst und einen guten Chat aufbaust, um spielerisch leicht auf ein Date zu kommen - egal ob du die Frauen bei Instagram, beim Online-Dating oder beim Frauen-Ansprechen kennengelernt hast!',
					icon: 'chatting'
				},
				{
					id: 5,
					title: 'Schritt',
					content: 'Du bekommst klare Leitfäden für erste, zweite und weitere Dates, um schnellstmöglich eine tiefe Verbindung zu einer Frau herstellen zu können. Wir entwickeln für dich eine individuelle Date-Routine, die dir Sicherheit und Erfolg bei Dates garantiert. Zusätzlich lernst du auch, wie du dich verhältst, wenn du mit einer Frau in eine Beziehung kommen möchtest und wie du dich in einer Beziehung verhältst, damit diese auch langfristig hält. In der Regel haben meine Teilnehmer innerhalb von 3 bis 6 Wochen regelmäßig Dates mit sehr attraktiven Frauen und können sich dadurch aus der Fülle heraus eine gute Partnerin aussuchen!',
					icon: 'perfektion'
				},
			],
		};
	},
};
</script>

<template>
	<!-- Content -->
	<section class="pt-10 sm:pt-14 container">
		<!-- Title -->
		<div class="text-center">
			<p class="text-2xl sm:text-5xl font-medium mb-2 text-ternary-dark dark:text-ternary-light">
				Meine Erfolgstrategie
			</p>
		</div>

		<div class="block sm:flex sm:gap-10 mt-10 sm:mt-20" v-for="vorgeh in vorgehen" :key="vorgeh.id">
			<!-- Image Left -->
			<div class="w-full sm:w-2/4 mb-7 sm:mb-0 hidden md:block" v-if="vorgeh.id % 2 != 0">
				<img :src="require(`@/assets/images/${vorgeh.icon}.svg`)" class="w-96" :alt="vorgeh.id" />
			</div>

			<!-- Content -->
			<div class="w-full sm:w-2/4 text-left">
				<p class="font-general-medium py-1 text-2xl sm:text-3xl text-primary-dark dark:text-primary-light">
					{{ vorgeh.id }}. {{ vorgeh.title }}
				</p>
				<p class="font-general-regular mb-4 text-ternary-dark dark:text-ternary-light text-lg">
					{{ vorgeh.content }}
				</p>
			</div>

			<!-- Image Right -->
			<div class="w-full sm:w-2/4 mb-7 sm:mb-0 hidden md:block" v-if="vorgeh.id % 2 == 0">
				<img :src="require(`@/assets/images/${vorgeh.icon}.svg`)" class="w-96" :alt="vorgeh.id" />
			</div>

			<!-- Image Right Mobile -->
			<div class="w-full sm:w-2/4 mb-7 sm:mb-0 md:hidden">
				<img :src="require(`@/assets/images/${vorgeh.icon}.svg`)" class="w-96" :alt="vorgeh.id" />
			</div>
		</div>
		<br />

		<!-- Load beratungs button -->
		<div class="mt-2 sm:mt-5 flex justify-center">
			<router-link to="/beratung"
				class="font-general-medium flex items-center px-6 py-3 rounded-lg shadow-lg hover:shadow-xl bg-indigo-500 hover:bg-indigo-600 focus:ring-1 focus:ring-indigo-900 text-white text-lg sm:text-xl duration-300">
				<Button title="Buche jetzt dein kostenloses Beratungsgespräch" />
			</router-link>
		</div>
	</section>
</template>

<style scoped></style>
