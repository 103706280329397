<script>
import Button from '../../components/reusable/Button.vue';
import LazyYoutube from "@/components/reusable/LazyYoutube.vue";

export default {
	name: 'HomeVideo',
	components: {
		Button,
		LazyYoutube
	},
};
</script>

<template>
	<!-- Content -->
	<section class="pt-10 sm:pt-14">
		<!-- Projects grid title -->
		<div class="text-center">
			<p class="py-2 text-2xl sm:text-2xl font-medium mb-2 text-ternary-dark dark:text-ternary-light">
				Mit Hendrik Matis Unterstützung haben bereits <b>über 350 Männer</b> ihren Weg zu einem erfüllten
				Dating-Leben und der idealen Partnerin gefunden.
			</p>
			<br />

			<div class="block sm:flex sm:gap-10 mt-10">
				<div class="w-full sm:w-1/2 flex items-center">
					<LazyYoutube :youtubeUrl="'oFY43PAZnS4'" :youtubeClass="'w-full'"
						:title="'Die beste Möglichkeit Frauen kennenzulernen!'" />
				</div>

				<div class="w-full sm:w-1/2 text-left sm:flex sm:items-center">
					<div>
						<p
							class="font-general-regular mb-4 text-ternary-dark dark:text-ternary-light text-lg sm:text-base">
							Hier sind 4 Punkte, die unsere Kunden an uns schätzen:
							<br /><br />

							<b>1. Klare Anleitung</b>: Mit meiner Schritt-für-Schritt-Methode lernst du, wie du jede Woche
							planbar 1-2 Dates bekommst – sowohl online als auch offline.
							<br /><br />

							<b>2. Zeiteffizienz</b>: Ich zeige dir, wie du trotz stressigem Job und vielen Hobbys, entspannt
							Frauen kennenlernen kannst.
							<br /><br />

							<b>3. Passende Partnerin</b>: Mein Ansatz ermöglicht es dir, aus einer Vielzahl von Dates genau die
							Frau auszuwählen, die perfekt als langfristige Partnerin zu dir passt.
							<br /><br />

							<b>4. Persönliche Weiterentwicklung</b>: Durch neu erlernte Social Skills und kommunikative
							Fähigkeiten steigerst du nicht nur deinen Erfolg beim Dating, sondern auch im Job oder
							Business.

						</p>
					</div>
				</div>



			</div>
			<br />

			<!-- Load beratungs button -->
			<div class="mt-2 sm:mt-5 flex justify-center">
				<router-link to="/beratung"
					class="font-general-medium flex items-center px-6 py-3 rounded-lg shadow-lg hover:shadow-xl bg-indigo-500 hover:bg-indigo-600 focus:ring-1 focus:ring-indigo-900 text-white text-lg sm:text-xl duration-300">
					<Button title="Buche jetzt dein kostenloses Beratungsgespräch" />
				</router-link>
			</div>
		</div>
	</section>
</template>

<style scoped></style>
