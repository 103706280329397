<script>
import Button from '../../components/reusable/Button.vue';

export default {
	components: {
		Button,
	},
	data: () => {
		return {
			bios: [
				{
					id: 'I',
					title: 'AKADEMIKER ODER UNTERNEHMER MIT WENIG ZEIT',
					bio: 'Dein beruflicher Erfolg spricht für sich – du hast hart gearbeitet, um dorthin zu kommen, wo du jetzt bist. Doch genau dieser Erfolg bringt eine Herausforderung mit sich: Dir fehlt die Zeit, neue Frauen kennenzulernen. Du kannst es dir nicht leisten, stundenlang am Handy zu sitzen und planlos auf Online-Apps zu verbringen. Du bist auch nicht mehr in dem Alter, wo du jedes Wochenende ausgehen willst, um dort mal eine Frau kennenzulernen. Was also jetzt tun, um Dates zu bekommen?',
				},
				{
					id: 'II',
					title: 'DU LANDEST BEI FRAUEN UNTER DEINEM NIVEAU',
					bio: 'Du weißt, dass du viel zu bieten hast – beruflich läuft es gut, du hast einen interessanten Lifestyle und eine attraktive Persönlichkeit. Doch aus irgendeinem Grund ziehst du immer wieder Frauen an, die nicht auf Augenhöhe mit dir sind. Es frustriert dich, dass du dich oft mit weniger zufrieden gibst, obwohl du weißt, dass du mehr verdient hast. Wie schaffst du es, Frauen zu finden, die wirklich deinem Wert entsprechen?',
				},
				{
					id: 'III',
					title: 'DU WÜNSCHST DIR EINE PARTNERIN MIT QUALITÄT',
					bio: 'Du bist an einem Punkt in deinem Leben angekommen, an dem du keine Spielchen mehr spielen möchtest. Du suchst eine Frau, die nicht nur attraktiv ist, sondern auch die Werte, Ambitionen und Qualitäten mitbringt, die zu deinem Lebensstil passen. Doch solche Frauen zu treffen, scheint schwieriger als gedacht. Wie findest du die Partnerin, die wirklich zu dir passt und mit der du eine erfüllte Beziehung aufbauen kannst?',
				},
			],
		};
	},
};
</script>

<template>
	<div>
		<div class="block sm:flex sm:gap-10 mt-10 sm:mt-20">
			<!-- About profile image -->
			<div class="w-full sm:w-2/4 flex items-center">
				<img src="@/assets/images/achievements.jpg" class="w-full h-full object-cover"
					alt="Hendrik flirtet beim Essen mit einer Frau" />
			</div>

			<!-- About details -->
			<div class="w-full sm:w-3/4 text-left">
				<p class="font-general-medium py-1 text-2xl sm:text-3xl text-primary-dark dark:text-primary-light">
					Für wen ist mein Coaching geeignet?
				</p>
				<br />
				<div v-for="bio in bios" :key="bio.id"
					class="flex ml-4 font-general-regular mt-4 mb-4 text-ternary-dark dark:text-ternary-light text-lg">
					<div class="flex flex-col pl-4">
						<p class="text-md text-ternary-dark mb-1 text-2xl sm:text-2xl mt-2 font-bold dark:text-ternary-light">
							{{ bio.title }}
						</p>
						<p class="text-md text-ternary-dark mt-2 mb-2 mr-3 dark:text-ternary-light">{{ bio.bio }}</p>
					</div>
				</div>
			</div>

		</div>
		<br />

		<!-- Load beratungs button -->
		<div class="mt-2 sm:mt-5 flex justify-center">
			<router-link to="/beratung"
				class="font-general-medium flex items-center px-6 py-3 rounded-lg shadow-lg hover:shadow-xl bg-indigo-500 hover:bg-indigo-600 focus:ring-1 focus:ring-indigo-900 text-white text-lg sm:text-xl duration-300">
				<Button title="Buche jetzt dein kostenloses Beratungsgespräch" />
			</router-link>
		</div>
	</div>
</template>