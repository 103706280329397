<script>
import Button from '../../components/reusable/Button.vue';

export default {
	components: {
		Button,
	},
	data: () => {
		return {
			topics: [
				{
					id: 'I',
					title: 'Ansprechangst \nbesiegen',
					content: 'Du siehst häufig Frauen, die dir gefallen und die du gerne Kennenlernen würdest, aber du verspürst extreme innere Widerstände diese Frauen anzusprechen.',
				},
				{
					id: 'II',
					title: 'Zu verkopft \nund rational',
					content: 'Wenn du mal mit Frauen in Kontakt kommst, sind deine Gespräche oft sehr emotionslos und entsteht keine Spannung zwischen dir und der Frau.',
				},
				{
					id: 'III',
					title: 'Kein Erfolg beim Online Dating',
					content: 'Du verbringst viel Zeit am Handy bekommst aber zu wenige Matches und wenn Frauen antworten, bekommst du nur Dates mit Frauen, die dir nicht wirklich gefallen.',
				},
				{
					id: 'IV',
					title: 'Mit Frauen \nChatten',
					content: 'Wenn du mal eine Nummer bekommst oder ein Match hast, verlaufen sich die Chats häufig im Sand, weil sie eintönig und langweilig sind.',
				},
			],
		};
	},
};
</script>

<template>
	<div class="mt-10 sm:mt-20 bg-primary-light dark:bg-ternary-dark shadow-sm">
		<p class="pt-20 py-2 text-2xl sm:text-5xl font-medium mb-2 text-ternary-dark dark:text-ternary-light">
			An diesen Schwierigkeiten scheitern Männer
		</p>
		<div class="font-general-regular container mx-auto py-16 block sm:flex sm:justify-between sm:items-center">
			<!-- Mobile View -->
			<div v-for="topic in topics" :key="topic.id"
				class="font-general-regular m-2 text-ternary-dark dark:text-ternary-light text-lg sm:hidden">
				<span
					class="block text-md text-ternary-dark font-['serif'] dark:text-ternary-light mb-2 text-8xl sm:text-4xl">
					{{ topic.id }}
				</span>
				<span class="block text-md text-ternary-dark dark:text-ternary-light mb-2 text-2xl sm:text-4xl">
					{{ topic.title }}
				</span>
				<span class="block text-md text-ternary-dark dark:text-ternary-light">
					{{ topic.content }}
				</span>
			</div>

			<!-- Desktop View -->
			<div class="w-full hidden sm:flex text-lg">
				<div v-for="topic in topics" :key="topic.id" class="flex-1 p-5 m-2 bg-white shadow-lg rounded-xl">
					<div class="text-md text-ternary-dark font-['serif'] mb-2 text-2xl sm:text-7xl">
						{{ topic.id }}
					</div>
					<div class="text-md text-ternary-dark mb-2 text-2xl whitespace-pre-line">
						{{ topic.title }}
					</div>
					<div class="text-md text-ternary-dark align-top py-2">
						{{ topic.content }}
					</div>
				</div>
			</div>
		</div>

		<!-- Load beratungs button -->
		<div class="mt-2 sm:mt-5 flex justify-center">
			<router-link to="/beratung"
				class="font-general-medium flex items-center px-6 py-3 rounded-lg shadow-lg hover:shadow-xl bg-indigo-500 hover:bg-indigo-600 focus:ring-1 focus:ring-indigo-900 text-white text-lg sm:text-xl duration-300">
				<Button title="Buche jetzt dein kostenloses Beratungsgespräch" />
			</router-link>
		</div>
		<br />
		<br />
	</div>
</template>