<script>
import Button from '../../components/reusable/Button.vue';

export default {
	name: 'AboutHendrik',
	components: {
		Button,
	},
	setup() {
		return {
			bios: [
				{
					id: 1,
					bio:
						'Hendrik Mati ist Gründer und leitender Coach der Elite-Dating-Academy. Er unterstützt hauptsächlich Akademiker und Unternehmer durch maßgeschneiderte Beratung, intensives Coaching und praxisorientiertes Training dabei, eine perfekte Partnerin zu finden.',
				},
				{
					id: 2,
					bio:
						'Hendrik war in seiner Jugend ein erfolgreicher Leistungssportler. Auf dem alten Foto siehst du, dass er als junger Mann nicht gerade der Typ war, der optisch gut bei Frauen angekommen ist. Zusätzlich war er sehr schüchtern und hatte wenig Selbstbewusstsein – kein Wunder also, dass er damals keinen Erfolg bei Frauen hatte.',
				},
				{
					id: 3,
					bio:
						'Nachdem er seinen Traum, Fußballprofi zu werden, wegen einer schweren Verletzung aufgeben musste, widmete er sich intensiv den Themen Fitness, Ernährung und Mentaltraining. Diese Arbeit an sich selbst und seinem Mindset machte ihn Schritt für Schritt zu einem attraktiveren Mann.',
				},
				{
					id: 4,
					bio:
						'Nach dem schmerzhaften Ende seiner ersten längeren Beziehung beschloss Hendrik, sich auch intensiv mit den Themen Dating und Partnerschaft auseinanderzusetzen. Seine persönliche Entwicklung führte ihn dazu, die Kunst des Datings zu studieren und zu meistern, um ein selbstbestimmtes und erfülltes Dating-Leben aufzubauen.',
				},
				{
					id: 5,
					bio:
						'In den darauffolgenden Jahren sprach er weltweit tausende Frauen im Alltag, im Club und über Online-Dating-Plattformen an. Dabei testete er zahlreiche Ansätze und analysierte, was wirklich funktioniert. Hendrik erkannte, dass erfolgreiche Dates mit attraktiven Frauen kein Zufall sind, sondern das Ergebnis klarer Strategien und erlernbarer Abläufe.',
				},
				{
					id: 6,
					bio:
						'Mit nur 24 Jahren begann er, seine Erkenntnisse auf YouTube zu teilen und seine ersten Kunden im Bereich Dating zu coachen. Heute, sieben Jahre später, hat Hendrik mit seinem Coaching-System über 350 Männer erfolgreich dabei unterstützt, nicht nur mehr Dates mit attraktiven Frauen zu bekommen, sondern auch die richtige Partnerin für eine glückliche Beziehung zu finden.',
				},
				{
					id: 7,
					bio:
						'Durch seine langjährige Erfahrung in der Zusammenarbeit mit Männern in unterschiedlichen Ausgangssituationen weiß er genau, wie er jedem einzelnen Mann schnell und effektiv zu einem erfüllten Dating-Leben verhelfen kann.',
				},
			],
		};
	},
};
</script>

<template>
	<div>
		<div class="block sm:flex sm:gap-10 mt-10 sm:mt-20">
			<!-- About details -->
			<div class="w-full sm:w-3/4 text-left">
				<p class="font-general-medium py-1 text-2xl sm:text-3xl text-primary-dark dark:text-primary-light">
					Über Hendrik
				</p>
				<p v-for="bio in bios" :key="bio.id"
					class="font-general-regular mb-4 text-ternary-dark dark:text-ternary-light text-lg"
					v-html="bio.bio">
				</p>
			</div>

			<!-- About profile image -->
			<div class="w-full sm:w-2/4 flex items-center">
				<img src="@/assets/images/hendrik.jpg" class="w-full h-full object-cover"
					alt="Hendrik steht hinter einer Türe und schaut in die Ferne" />
			</div>
		</div>
		<br />

		<!-- Load beratungs button -->
		<div class="mt-2 sm:mt-5 flex justify-center">
			<router-link to="/beratung"
				class="font-general-medium flex items-center px-6 py-3 rounded-lg shadow-lg hover:shadow-xl bg-indigo-500 hover:bg-indigo-600 focus:ring-1 focus:ring-indigo-900 text-white text-lg sm:text-xl duration-300">
				<Button title="Buche jetzt dein kostenloses Beratungsgespräch" />
			</router-link>
		</div>
	</div>
</template>

<style scoped></style>
