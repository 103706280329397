<script>

export default {
	name: 'Buehne',
	setup() {
		return {
			bios: [
				{
					id: 0,
					bio:
						'<b>Wenn es dir wie den meisten Männern Ü30 geht, dann kennst du wahrscheinlich diese Probleme hier:</b>',
				},
				{
					id: 1,
					bio:
						'Du hast keine Zeit stundenlang beim Online-Dating zu swipen, zu chatten oder auszugehen, um Frauen anzusprechen, weil du beruflich zu eng eingebunden bist.',
				},
				{
					id: 2,
					bio:
						'Beim Online-Dating bekommst du kaum Matches, und wenn doch, verlaufen sich die Chats im Sand. Und wenn mal Dates zustande kommen, dann mit Frauen, die dir eigentlich nicht wirklich gut gefallen.',
				},
				{
					id: 3,
					bio:
						'Du hast einen interessanten Lebensstil und kannst einer Frau viel bieten, aber Frauen erkennen deinen Wert nicht und landen bei anderen Männern.',
				},
				{
					id: 4,
					bio:
						'Du wünschst dir langfristig eine perfekte Partnerin, mit der du gemeinsame Urlaube und Wochenendausflüge erleben kannst, doch stattdessen verbringst du deine Zeit oft alleine und fühlst dich unerfüllt.',
				},
				{
					id: 5,
					bio:
						'Du möchtest mal eine Familie gründen und Kinder haben, aber die richtige Frau fehlt. Die Angst vor dem Alleinsein im Alter wird immer größer. <b>Keine Sorge</b>, so geht es den meisten dort draußen!',
				},
			],
		};
	},
};
</script>

<template>
	<p class="py-2 text-2xl sm:text-4xl font-medium mb-2 text-ternary-dark dark:text-ternary-light mt-20">
		Warum Männer Ü30 kein erfülltes Dating-Leben haben und warum sie nie eine perfekte Partnerin finden werden…
	</p>
	<div class="block sm:flex sm:gap-10 mt-8">
		<!-- Buehne profile image -->
		<div class="w-full sm:w-1/2 flex items-center">
			<img src="@/assets/images/sad_hendrik.jpg" class="w-full h-full object-cover"
				alt="Hendrik-Vorgänger ist traurig" />
		</div>

		<!-- Buehne details -->
		<div class="w-full sm:w-1/2 text-left sm:flex sm:items-center">
			<div>
				<p v-for="bio in bios" :key="bio.id"
					class="font-general-regular mb-4 text-ternary-dark dark:text-ternary-light text-lg sm:text-xl"
					v-html="bio.bio">
				</p>
			</div>
		</div>

	</div>
</template>

<style scoped></style>
