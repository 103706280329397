<script>
import Button from '../../components/reusable/Button.vue';

export default {
	name: 'NummernAustausch',
	components: {
        Button,
    },
	setup() {
		return {
			bios: [
				{
					id: 1,
					bio:
						'<b>Warum du beim Online-Dating keine Frau kennenlernst, die dir wirklich gefällt und warum du auch keine Partnerin im echten Leben findest …</b>',
				},
				{
					id: 2,
					bio:
						'Viele Männer Ü30 glauben, durch das Konsumieren von Dating-Videos und Ratgebern oder Wochenend-Workshops erfolgreicher bei Frauen zu werden, doch sie können das Gelernte nicht umsetzen, weil sie keinen individuellen, langfristigen Fahrplan haben und kein regelmäßiges Feedback zur Umsetzung bekommen.',
				},
				{
					id: 3,
					bio:
						'Sie machen ein Fotoshooting und nutzen die Bezahlfunktionen beim Online-Dating, verstehen aber nicht, dass gestellte Fotos nicht gut bei Frauen ankommen und dass der Algorithmus beim Online-Dating nicht besser wird, wenn man Geld dafür bezahlt.',
				},
				{
					id: 4,
					bio:
						'Sie denken, spezielle Sprüche oder psychologische Flirt-Techniken seien der Schlüssel zu mehr Dating-Erfolg, erkennen aber nicht, dass sich der moderne Dating-Markt komplett verändert hat: Jede halbwegs attraktive Frau hat durch Social Media oder Online-Dating eine riesige Auswahl an Männern und die Konkurrenz hat sich mehr als verhundertfacht…',
				},
				{
					id: 5,
					bio:
						'Männer, die ihre Prioritäten auf Karriere und Business setzen, verlieren oft wertvolle Zeit mit ineffektiven Ansätzen und daten häufig nur Frauen unter ihrem Niveau, weil sie nicht wissen, wie sie sich online oder offline richtig vermarkten und wie sie sich von ihrer Konkurrenz abheben können.',
				},
			],
		};
	},
};
</script>

<template>
	<div>
		<div class="block sm:flex sm:gap-10 mt-10 sm:mt-20">
			<!-- About details -->
			<div class="w-full sm:w-1/2 text-left sm:flex sm:items-center">
				<div>
					<p v-for="bio in bios" :key="bio.id"
						class="font-general-regular mb-4 text-ternary-dark dark:text-ternary-light text-lg lg:text-xl"
						v-html="bio.bio">
					</p>
				</div>
			</div>

			<!-- About profile image -->
			<div class="w-full sm:w-1/2 flex items-center">
				<img src="@/assets/images/hendrik_tinder.jpg" class="w-full h-full object-cover"
					alt="Hendrik-Vorgänger nutzt Tinder" />
			</div>
		</div>
		<br />
		<br />

		<!-- Load beratungs button -->
		<div class="mt-2 sm:mt-5 flex justify-center">
			<router-link to="/beratung"
				class="font-general-medium flex items-center px-6 py-3 rounded-lg shadow-lg hover:shadow-xl bg-indigo-500 hover:bg-indigo-600 focus:ring-1 focus:ring-indigo-900 text-white text-lg sm:text-xl duration-300">
				<Button title="Buche jetzt dein kostenloses Beratungsgespräch" />
			</router-link>
		</div>
	</div>
</template>

<style scoped>
@media (min-width: 640px) {
	.object-cover {
		object-fit: cover;
	}
}
</style>