<script>
export default {
	data: () => {
		return {
			clients: [
				{
					id: 1,
					title: 'rtl',
				},
				{
					id: 2,
					title: 'wdr',
				},
				{
					id: 3,
					title: 'swr2',
				},
			],
		};
	},
};
</script>

<template>
	<div class="specialBackgroundAd h-32">
		<div class="pt-10 font-general-regular container mx-auto block sm:flex sm:justify-between sm:items-center">
			<table class="w-full">
				<tbody v-if="false" class="font-general-regular m-2 text-ternary-dark dark:text-ternary-light text-lg inline-flex">
					<td v-for="client in clients" :key="client.id"
						class="text-md text-ternary-dark dark:text-ternary-light align-top py-2 px-5 sm:px-10">
						<h1
							class="font-general-semibold text-4xl sm:text-7xl text-center sm:text-left text-primary-light uppercase tracking-wider">
							<img :src="require(`@/assets/images/logo-${client.title}.svg`)" class="h-10" :alt="client.title"  />
						</h1>
					</td>
				</tbody>
			</table>
		</div>
	</div>
</template>

<style>
.specialBackgroundAd {
	background: linear-gradient(to bottom, rgb(90 89 87 / 36%) 10%, rgba(255, 255, 255, 1) 100%);
}
</style>
