<script>
import AboutClientSingle from "./AboutClientSingle.vue";
import Button from '../../components/reusable/Button.vue';

export default {
	components: {
		AboutClientSingle,
		Button,
	},
	data: () => {
		return {
			clientsHeading:
				"Das sagen meine Kunden:",
			clients: [
				{
					id: 1,
					name: "Michi",
					challenges: "Wenig Zeit wegen der Arbeit und keine Dates mit guten Frauen",
					goals: "Locker auf Frauen auf der Straße zugehen können und planbar Dates zu bekommen",
					results:
						"Viele Dates mit unterschiedlichen Frauen aus denen er nun auswählen kann.",
					youtubeId: "Wozl2Dnq7tQ",
				},
				{
					id: 2,
					name: "Daniel",
					challenges:
						"Nach langer Beziehung eingerostet und keinen Plan für neue Dates",
					goals:
						"Ohne Angst Frauen ansprechen und authentisch Flirten lernen",
					results:
						"Keine Ansprechangst mehr und regelmäßig Dates mit attraktiven Frauen",
					youtubeId: "WsKlKXJWlZ4",
				},
				{
					id: 3,
					name: "Leon",
					work: "Selbstständig",
					challenges:
						"Nach langer Beziehung in ein Loch gefallen und Potential nicht genutzt",
					goals:
						"Neben Selbstständigkeit Frauen auf Augenhöhe kennenlernen",
					results:
						"Regelmäßig Dates und daraus eine feste Partnerin und Beziehung gefunden",
					youtubeId: "qpuVUuHMnoA",
				},
				{
					id: 4,
					name: "Benji",
					work: "IT Consultant",
					challenges:
						"Wenig Erfahrung mit Frauen, weil Fokus auf dem Studium lag",
					goals: "Mehr Dates mit Frauen, die er aktiv angesprochen hat",
					results:
						"Dated 2 Frauen parallel und lernt regelmäßig neue Frauen kennen",
					youtubeId: "aXwP3ZZJ44o",
				},
			],
		};
	},
};
</script>

<template>
	<div class="mt-5 sm:mt-10">
		<p class="font-general-medium text-2xl sm:text-3xl text-primary-dark dark:text-primary-light">
			{{ clientsHeading }}
		</p>
		<div class="grid sm:grid-cols-2 grid-cols-1 mt-10 sm:mt-14 gap-2">
			<AboutClientSingle v-for="client in clients" :key="client.id" :client="client" />
		</div>

		<!-- Load beratungs button -->
		<div class="mt-2 sm:mt-5 flex justify-center">
			<router-link to="/beratung"
				class="font-general-medium flex items-center px-6 py-3 rounded-lg shadow-lg hover:shadow-xl bg-indigo-500 hover:bg-indigo-600 focus:ring-1 focus:ring-indigo-900 text-white text-lg sm:text-xl duration-300">
				<Button title="Buche jetzt dein kostenloses Beratungsgespräch" />
			</router-link>
		</div>
	</div>
</template>
