<script>
import Button from '../../components/reusable/Button.vue';
import Clients from '../../components/home/Clients';
export default {
	name: 'AppBaner',
	components: {
		Button,
		Clients,
	},
};
</script>

<template>
	<section class="Banner">
		<div class="specialBackground">

			<div class="p-10 block sm:flex sm:gap-10 flex sm:flex-row min-h-screen contentBanner">
				<!-- Placeholder -->
				<div class="w-full sm:w-1/2 hidden sm:flex items-center h-full object-cover" />

				<!-- Content -->
				<div class="w-full sm:w-3/4 sm:mt-0 md:mt-60">
					<p id="introText"
						class="font-general-semibold text-2xl sm:text-2xl md:text-3xl lg:text-3xl text-center sm:text-left text-ternary-light">
						Als Mann Ü30 planbar attraktive Frauen daten und die perfekte Partnerin finden - ohne viel Aufwand und Zeiteinsatz
					</p>
					<br />
					<p class="font-general-medium mt-2 text-xl text-center sm:text-left text-white">
						Mit einem individuell auf dich abgestimmten Schritt-für-Schritt-System planbar zu Dates und zur perfekten Partnerin.
					</p>

					<!-- Load buttons -->
					<div class="mt-2 sm:mt-5 flex sm:justify-start justify-center">
						<router-link to="/beratung"
							class="font-general-medium flex items-center px-6 py-3 rounded-lg shadow-lg hover:shadow-xl bg-indigo-500 hover:bg-indigo-600 focus:ring-1 focus:ring-indigo-900 text-white text-lg sm:text-xl duration-300">
							<Button title="Buche jetzt dein kostenloses Beratungsgespräch" />
						</router-link>
					</div>
				</div>
			</div>
		</div>

		<!-- AdClients -->
		<div>
			<Clients />
		</div>
	</section>
</template>

<style scoped>
#introText {
	line-height: 2.8rem;
}

.Banner {
	background: url(@/assets/images/background_banner.jpeg) no-repeat center center / cover scroll;
	margin-bottom: 0;
}

.contentBanner {
	min-height: 76vh;
	min-height: calc(100vh - 64px - 99px);
}

@media (min-width: 640px) {
	.contentBanner {
		min-height: 83.7vh;
		min-height: calc(100vh - 64px - 83px);
	}
}
</style>
